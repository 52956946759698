var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutPage',{attrs:{"title":"Comisiones"}},[_c('section',[_c('v-expansion-panels',{staticClass:"mb-3",model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',{key:0},[_c('v-expansion-panel-header',{staticClass:"outline_none",attrs:{"disable-icon-rotate":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('span',{staticClass:"primary--text text-subtitle-1 mt-0"},[_vm._v("Filtros")]),_c('v-icon',{attrs:{"size":"33","color":"success"}},[_vm._v(" search ")])]},proxy:true}])}),_c('v-expansion-panel-content',[_c('v-row',{staticClass:"pt-0 mx-5"},[_c('v-col',{attrs:{"cols":"8","sm":"6","md":"4","lg":"2"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"label":"Fecha inicio","readonly":"","persistent-hint":"","value":_vm.formatDate(_vm.fechaInicio)}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"locale":"es-ar","no-title":"","scrollable":"","max":new Date().toISOString().substr(0, 10)},on:{"change":function($event){{
                      _vm.page = 1;
                      _vm.getData();
                    }}},model:{value:(_vm.fechaInicio),callback:function ($$v) {_vm.fechaInicio=$$v},expression:"fechaInicio"}})],1)],1),_c('v-col',{attrs:{"cols":"8","sm":"6","md":"4","lg":"2"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"label":"Fecha fin","readonly":"","persistent-hint":"","value":_vm.formatDate(_vm.fechaFin)}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"locale":"es-ar","no-title":"","scrollable":"","min":_vm.fechaInicio,"max":new Date().toISOString().substr(0, 10)},on:{"change":function($event){{
                      _vm.page = 1;
                      _vm.getData();
                    }}},model:{value:(_vm.fechaFin),callback:function ($$v) {_vm.fechaFin=$$v},expression:"fechaFin"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-alert',{staticClass:"mx-5 ",staticStyle:{"font-family":"Montserrat, sans-serif !important","font-size":"1.2em"},attrs:{"dense":"","elevation":"3","icon":"paid","outlined":"","prominent":"","text":"","type":"success"}},[_vm._v(" Total: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.formatImport(_vm.totalComisiones || 0)))])])],1)],1),_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"start"}},[_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mt-4",attrs:{"small":"","color":"green","dark":""},on:{"click":function($event){return _vm.downloadExcel()}}},[_vm._v(" Exportar "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-microsoft-excel")])],1)],1)],1)],1)],1)],1),_c('p',{staticClass:"text-start ml-3 mb-1"},[_vm._v("Filtrar por:")]),_c('v-tabs',{staticClass:"mt-4 mb-4 ",attrs:{"color":"black","background-color":"grey lighten-4","dark":""},on:{"change":function($event){return _vm.getData()}},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{staticClass:"black--text"},[_vm._v("Fecha de cobro")]),_c('v-tab',{staticClass:"black--text"},[_vm._v("Fecha de rendicion")])],1),_c('v-data-table',{attrs:{"headers":_vm.cabezera,"items":_vm.movimientos,"loading":_vm.loading,"hide-default-footer":"","no-data-text":"Sin datos para mostrar","loading-text":"Cargando datos","page":_vm.page,"items-per-page":_vm.rowsPerPage},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.MontoPago",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatImport(item.MontoPago))+" ")]}},{key:"item.MontoMovimiento",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatImport(item.MontoMovimiento))+" ")]}},{key:"item.MontoComision",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatImport(item.MontoComision))+" ")]}},{key:"item.MontoImpuesto",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatImport(item.MontoImpuesto))+" ")]}},{key:"item.MontoComisionproveedor",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatImport(item.MontoComisionproveedor))+" ")]}},{key:"item.MontoImpuestoproveedor",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatImport(item.MontoImpuestoproveedor))+" ")]}},{key:"item.ImpuestoComision",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatImport(item.ImpuestoComision))+" ")]}},{key:"item.Montoproveedor",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatImport(item.Montoproveedor))+" ")]}},{key:"item.Subtotal",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatImport(item.Subtotal))+" ")]}},{key:"item.CreatedAt",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.CreatedAt))+" ")]}},{key:"item.FechaOperacion",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.FechaOperacion ? _vm.formatDate(item.FechaOperacion) : "-")+" ")]}},{key:"item.FechaPago",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.FechaPago))+" ")]}},{key:"footer",fn:function(){return [_c('v-row',{staticClass:"no-gutters mt-2 mx-2"},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',[_vm._v("Mostrar")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"dark":"","text":"","color":"primary"}},on),[_vm._v(" "+_vm._s(_vm.rowsPerPage)+" "),_c('v-icon',[_vm._v("arrow_drop_down")])],1)]}}])},[_c('v-list',_vm._l((_vm.rowsPerPageArray),function(number,index){return _c('v-list-item',{key:index,on:{"click":function($event){{
                      _vm.page = 1;
                      _vm.rowsPerPage = number;
                      _vm.getData();
                    }}}},[_c('v-list-item-title',[_vm._v(_vm._s(number))])],1)}),1)],1)],1)],1)]},proxy:true}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},on:{"input":function($event){return _vm.getData()}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }