<template>
  <LayoutPage title="Comisiones">
    <section>
      <!-- filtros table  -->
      <v-expansion-panels v-model="panel" class="mb-3">
        <v-expansion-panel :key="0">
          <v-expansion-panel-header class="outline_none" disable-icon-rotate>
            <template v-slot:actions>
              <span class="primary--text text-subtitle-1 mt-0">Filtros</span>
              <v-icon size="33" color="success"> search </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- 1 fila de filtros -->
            <v-row class="pt-0 mx-5">
              <!-- seleccionar tipo de filtro -->
              <!--  <v-col cols="8" sm="6" md="4" lg="3">
                <v-autocomplete
                  label="Filtrar por"
                  :items="[
                    {
                      text: 'Fecha de acreditacion',
                      value: 'FechaOperacion',
                    },
                    {
                      text: 'Fecha de pago',
                      value: 'FechaPago',
                    },
                  ]"
                  v-model="filtroFecha"
                  @change="getData()"
                ></v-autocomplete>
              </v-col> -->
              <v-col cols="8" sm="6" md="4" lg="2">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  dark
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="ml-5"
                      label="Fecha inicio"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      persistent-hint
                      :value="formatDate(fechaInicio)"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechaInicio"
                    locale="es-ar"
                    no-title
                    scrollable
                    :max="new Date().toISOString().substr(0, 10)"
                    @change="
                      {
                        page = 1;
                        getData();
                      }
                    "
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="8" sm="6" md="4" lg="2">
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  dark
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class="ml-5"
                      label="Fecha fin"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      persistent-hint
                      :value="formatDate(fechaFin)"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechaFin"
                    locale="es-ar"
                    no-title
                    scrollable
                    :min="fechaInicio"
                    :max="new Date().toISOString().substr(0, 10)"
                    @change="
                      {
                        page = 1;
                        getData();
                      }
                    "
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" lg="4">
                <v-alert
                  dense
                  elevation="3"
                  icon="paid"
                  outlined
                  prominent
                  text
                  type="success"
                  class="mx-5 "
                  style="font-family: Montserrat, sans-serif !important; font-size: 1.2em;"
                >
                  Total:
                  <span class="font-weight-bold">{{
                    formatImport(totalComisiones || 0)
                  }}</span>
                </v-alert>
              </v-col>
            </v-row>

            <v-row no-gutters align="center" justify="start">
              <!-- FILTRO COMISIONES DE TODOS LOS CLIENTES -->
              <!--  <v-checkbox
                label="Comisiones de todos los clientes"
                v-model="filtroClientes"
                class="mr-4"
                @change="
                  () => {
                    page = 1;
                    getData();
                  }
                "
                hide-details
              ></v-checkbox> -->
              <!-- FILTRO REVERSIONES -->
              <!--   <v-checkbox
                label="Reversiones"
                v-model="filtroReversiones"
                @change="
                  () => {
                    page = 1;
                    getData();
                  }
                "
                hide-details
              ></v-checkbox> -->

              <v-col class="d-flex justify-end">
                <v-btn
                  small
                  color="green"
                  dark
                  class="mt-4"
                  @click="downloadExcel()"
                >
                  Exportar
                  <v-icon class="ml-2">mdi-microsoft-excel</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <p class="text-start ml-3 mb-1">Filtrar por:</p>
      <!-- TABS: COBRANZAS Y RETENCIONES -->
      <v-tabs
        v-model="tab"
        color="black"
        background-color="grey lighten-4"
        dark
        class="mt-4 mb-4 "
        @change="getData()"
      >
        <v-tab class="black--text">Fecha de cobro</v-tab>
        <v-tab class="black--text">Fecha de rendicion</v-tab>
      </v-tabs>

      <!--end filtros-->

      <v-data-table
        :headers="cabezera"
        :items="movimientos"
        :loading="loading"
        hide-default-footer
        no-data-text="Sin datos para mostrar"
        loading-text="Cargando datos"
        :page.sync="page"
        :items-per-page="rowsPerPage"
      >
        <template v-slot:[`item.MontoPago`]="{ item }">
          {{ formatImport(item.MontoPago) }}
        </template>

        <template v-slot:[`item.MontoMovimiento`]="{ item }">
          {{ formatImport(item.MontoMovimiento) }}
        </template>

        <template v-slot:[`item.MontoComision`]="{ item }">
          {{ formatImport(item.MontoComision) }}
        </template>
        <template v-slot:[`item.MontoImpuesto`]="{ item }">
          {{ formatImport(item.MontoImpuesto) }}
        </template>
        <template v-slot:[`item.MontoComisionproveedor`]="{ item }">
          {{ formatImport(item.MontoComisionproveedor) }}
        </template>
        <template v-slot:[`item.MontoImpuestoproveedor`]="{ item }">
          {{ formatImport(item.MontoImpuestoproveedor) }}
        </template>

        <template v-slot:[`item.ImpuestoComision`]="{ item }">
          {{ formatImport(item.ImpuestoComision) }}
        </template>

        <template v-slot:[`item.Montoproveedor`]="{ item }">
          {{ formatImport(item.Montoproveedor) }}
        </template>

        <template v-slot:[`item.Subtotal`]="{ item }">
          {{ formatImport(item.Subtotal) }}
        </template>

        <template v-slot:[`item.CreatedAt`]="{ item }">
          {{ formatDate(item.CreatedAt) }}
        </template>
        <template v-slot:[`item.FechaOperacion`]="{ item }">
          {{ item.FechaOperacion ? formatDate(item.FechaOperacion) : "-" }}
        </template>
        <template v-slot:[`item.FechaPago`]="{ item }">
          {{ formatDate(item.FechaPago) }}
        </template>

        <template v-slot:footer>
          <v-row class="no-gutters mt-2 mx-2">
            <v-flex xs12>
              <span>Mostrar</span>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn dark text color="primary" class="ml-2" v-on="on">
                    {{ rowsPerPage }}
                    <v-icon>arrow_drop_down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(number, index) in rowsPerPageArray"
                    :key="index"
                    @click="
                      {
                        page = 1;
                        rowsPerPage = number;
                        getData();
                      }
                    "
                  >
                    <v-list-item-title>{{ number }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-flex>
          </v-row>
        </template>
      </v-data-table>

      <!--PAGINACION-->
      <div class="text-center pt-2">
        <v-pagination
          v-model="page"
          :length="pageCount"
          @input="getData()"
        ></v-pagination>
      </div>
    </section>
  </LayoutPage>
</template>

<script>
import { mapGetters } from "vuex";
import reportes from "../../../services/reportes";
import LayoutPage from "../../../components/LayoutPage.vue";

import FileSaver from "file-saver";
import formatDate from "../../../utils/formatDate";
import formatImport from "../../../utils/formatImport";
import moment from "moment";
import Papa from "papaparse";

export default {
  name: "ReporteMovimientoIndex",
  components: { LayoutPage },
  data() {
    return {
      tab: 0,
      panel: 0,
      menu1: false,
      menu2: false,
      loading: false,
      fechaInicio: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      fechaFin: moment().format("YYYY-MM-DD"),

      headers: [
        {
          text: "Nombre de cliente",
          sortable: false,
          value: "NombreCliente",
          width: "260px",
        },

        { text: "Tipo", sortable: false, value: "Tipo", align: "center" },

        {
          text: "MontoPago",
          sortable: false,
          value: "MontoPago",
          width: "120px",
          align: "center",
        },
        {
          text: "Movimiento $",
          sortable: false,
          value: "MontoMovimiento",
          width: "130px",
          align: "center",
        },
        {
          text: "Comision $",
          sortable: false,
          value: "MontoComision",
          width: "120px",
          align: "center",
        },
        {
          text: "Comision %",
          sortable: false,
          value: "PorcentajeComision",
          width: "120px",
          align: "center",
        },
        {
          text: "IVA $",
          sortable: false,
          value: "MontoImpuesto",
          width: "120px",
          align: "center",
        },
        {
          text: "IVA %",
          sortable: false,
          value: "PorcentajeImpuesto",
          width: "120px",
          align: "center",
        },
        {
          text: "Comision proveedor $",
          sortable: false,
          value: "MontoComisionproveedor",
          width: "170px",
          align: "center",
        },
        {
          text: "Comision proveedor %",
          sortable: false,
          value: "PorcentajeComisionproveedor",
          width: "170px",
          align: "center",
        },
        {
          text: "IVA proveedor $",
          sortable: false,
          value: "MontoImpuestoproveedor",
          width: "170px",
          align: "center",
        },
        {
          text: "Subtotal",
          sortable: false,
          value: "Subtotal",
          width: "120px",
          align: "center",
        },
      ],

      movimientos: [],
      filtroClientes: false,
      filtroReversiones: false,

      //paginacion
      page: 1,
      pageCount: 0,
      rowsPerPage: 30,
      totalComisiones: 0,

      rowsPerPageArray: [30, 50, 100],
      formatDate,
      formatImport,

      exportar: false,
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    ...mapGetters(["getCurrentClientId"]),
    cabezera() {
      if (this.tab === 1) {
        return [
          /* {
            text: "Fecha",
            sortable: false,
            value: "FechaPago",
            width: "140px",
            align: "center",
          }, */
          {
            text: "Fecha",
            sortable: false,
            value: "FechaOperacion",
            width: "160px",
            align: "center",
          },
          ...this.headers,
        ];
      } else {
        return [
          {
            text: "Fecha",
            sortable: false,
            value: "FechaPago",
            width: "140px",
            align: "center",
          },
          ...this.headers,
        ];
      }
    },
  },
  watch: {
    getCurrentClientId() {
      this.page = 1;
      this.getData();
    },
  },
  methods: {
    async getData() {
      this.tab === 1
        ? await this.getDataMovimiento()
        : await this.getDataMovimientoTemporal();
    },

    async getDataMovimiento() {
      let sizeValue = this.exportar ? null : this.rowsPerPage;

      const params = {
        Number: this.page,
        Size: sizeValue,
        FechaInicio: moment(this.fechaInicio).format("DD-MM-yyyy"),
        FechaFin: moment(this.fechaFin).format("DD-MM-yyyy"),
        ClienteId: this.getCurrentClientId,
        FechaPago: false,
        //SoloReversiones: true,
      };

      if (this.filtroClientes) delete params.ClienteId;

      try {
        this.loading = true;
        this.menu1 = false;
        this.menu2 = false;

        const { data } = await reportes.reporteMovimiento(params);

        this.movimientos = data.data.Reportes;
        this.totalComisiones = data.data.Total;
        this.pageCount = data.data.LastPage;
        this.loading = false;
      } catch (err) {
        // console.error(err);
        this.loading = false;
        this.menu1 = false;
        this.menu2 = false;
        alert("error al comunicarse con el servidor");
      }
    },

    async getDataMovimientoTemporal() {
      let sizeValue = this.exportar ? null : this.rowsPerPage;
      const params = {
        Number: this.page,
        Size: sizeValue,
        FechaInicio: moment(this.fechaInicio).format("DD-MM-yyyy"),
        FechaFin: moment(this.fechaFin).format("DD-MM-yyyy"),
        ClienteId: this.getCurrentClientId,
        FechaPago: true,
      };
      if (this.filtroClientes) delete params.ClienteId;

      try {
        this.loading = true;
        this.menu1 = false;
        this.menu2 = false;

        const { data } = await reportes.reporteMovimientoTemporal(params);

        this.movimientos = data.data.Reportes;
        this.totalComisiones = data.data.Total;
        this.pageCount = data.data.LastPage;
        this.loading = false;
      } catch (err) {
        // console.error(err);
        this.loading = false;
        this.menu1 = false;
        this.menu2 = false;
        alert("error al comunicarse con el servidor");
      }
    },

    async downloadExcel() {
      this.exportar = true;

      await this.getData();

      // Crear una matriz con los encabezados de columna
      const fields = this.cabezera.map((h) => h.text);

      // Crear una matriz con los datos de la tabla
      let data = this.movimientos.map((m) =>
        [
          formatDate(m.FechaPago),
          this.filtroReversiones ? formatDate(m.FechaOperacion) : null,
          m.NombreCliente,
          m.Tipo,
          formatImport(m.MontoPago, true),
          formatImport(m.MontoMovimiento, true),
          formatImport(m.MontoComision, true),
          m.PorcentajeComision,
          formatImport(m.MontoImpuesto, true),
          m.PorcentajeImpuesto,
          formatImport(m.MontoComisionproveedor, true),
          m.PorcentajeComisionproveedor,
          formatImport(m.MontoImpuestoproveedor, true),
          formatImport(m.Subtotal, true),
        ].filter((d) => d !== null)
      );

      // Convertir los datos y encabezados a un objeto CSV
      const csvData = Papa.unparse({ fields, data }, { delimiter: ";" });

      // Crear un Blob con los datos CSV
      const csvFile = new Blob(["\uFEFF" + csvData], {
        type: "text/csv;charset=utf-8;",
      });

      // Guardar el archivo usando FileSaver.js
      FileSaver.saveAs(csvFile, "comisiones");

      this.exportar = false;
      await this.getData();
    },
  },
};
</script>

<style scoped>
::v-deep .v-messages__message {
  color: #aaa; /* Color de fondo del hint */
  font-size: 11px;
}
</style>
